<template>
    <v-dialog
        v-model="dialog"
        max-width="90vw"
        persistent
    >
        <v-card height="90vh">

            <v-toolbar color="primary" dark height="50" class="pr-3">
                <v-toolbar-title>{{ academicYear }} {{ formClassId }} Class Mark Sheet </v-toolbar-title>
                <v-btn
                        icon
                        color="white"
                        class="ml-auto"
                       @click="closeDialogMarkSheet"
                       small
                    >
                        <v-icon                            
                        >mdi-close</v-icon>
                    </v-btn>
            </v-toolbar>

            <v-card-title class="mb-6">
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search Student"
                    single-line
                    hide-details
                    class="mr-4"
                    clearable
                ></v-text-field>
                <v-btn color="primary" class="ml-4" @click="viewPDF">View PDF</v-btn>
            </v-card-title>

            <v-data-table
                :headers="headers"
                :items="markRecords"
                height="55vh"
                fixed-header
                :loading="loading" 
                hide-default-header 
                :search="search" 
            >
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th style="width:180px">
                               <span style="font-weight:bold">Student Name</span>
                            </th>
                            <template
                                v-for="(header, j) in headers"
                                
                            >
                                <th 
                                    :key=j
                                    v-if="header.value !== 'name'"
                                    style="width:100px"
                                >
                                    <div>{{ header.text }}</div>
                                    <div v-if="header.value !== 'name'" class="d-flex">
                                        <span class="mr-2" style="width:32px">CW</span>
                                        <span style="width:32px">EW</span>
                                    </div>
                                </th>
                            </template>
                        </tr>
                    </thead>
                </template>
                
                <template v-slot:item="props">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <tr v-bind="attrs" v-on="on">
                                <td>{{ props.item.name }}</td>
                                <template v-for="(header, i) in props.headers">
                                    <td 
                                        
                                        :key="i"
                                        v-if="header.value !== 'name'" 
                                    >
                                        <div class="d-flex" >
                                            <v-tooltip v-if="props.item[header.value].assigned" bottom >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span 
                                                        :class="courseMarkClass(props.item[header.value])"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    > 
                                                        {{ props.item[header.value].course_mark }} 
                                                    </span>
                                                </template>
                                                <span>{{ props.item[header.value].teacher }} </span>
                                            </v-tooltip>
                                            <span v-else></span>

                                            <v-tooltip v-if="props.item[header.value].assigned" bottom >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span 
                                                        :class="examMarkClass(props.item[header.value])"
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    > 
                                                        {{ props.item[header.value].exam_mark }} 
                                                    </span>
                                                </template>
                                                <span>{{ props.item[header.value].teacher }} </span>
                                            </v-tooltip>
                                            <span v-else></span>
                                            
                                        </div>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <span>{{ props.item.name }}</span>
                    </v-tooltip>
                </template>    
            </v-data-table>

            <v-overlay
                absolute
                :value="overlay"
                color="white"
                
            >
                <v-progress-circular
                    indeterminate 
                    size="64"                        
                    color="primary"
                ></v-progress-circular>
            </v-overlay> 

            <v-card-actions>
            
            </v-card-actions> 
        </v-card>

        <v-dialog
            v-model="dialogReport"
            max-width="90vw"
            persistent
        >
            <v-card
               height="90vh"
               style="position:relative; overflow-y:hidden;" 
            >                
                <v-toolbar color="primary" dark height="50" class="pr-3">
                <v-toolbar-title>{{ academicYear }} {{ formClassId }} Class Mark Sheet </v-toolbar-title>
                <v-btn
                        icon
                        color="white"
                        class="ml-auto"
                       @click="closeReport()"
                       small
                    >
                        <v-icon                            
                        >mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <iframe 
                    ref="pdf" 
                    style="width:100%; height:100%" 
                    class="report-viewer"  
                    :src="src"
                    @load="pdfLoaded"
                ></iframe>              
                <v-overlay
                    absolute
                    :value="overlayReport"
                    color="white"
                    opacity="0.60"
                >
                    <v-progress-circular
                        indeterminate
                        size="64"
                        color="primary"
                    ></v-progress-circular>
                </v-overlay> 
            </v-card>
        </v-dialog> 
    </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default{
    props: {
        dialogOpen: Boolean,
        academicTermId: Number,
        formClassId: String,
    },

    created () {
        this.initialize();
    },

    watch: {
        dialogOpen: {
            handler (val) {
                console.log(val)
            }
        }
    },

    data: function () {
        return {
            dialog: this.dialogOpen,
            dialogReport: false,
            overlay: false,
            headers: [],
            markRecords: [],
            loading: true,
            search: null,
            src: null,
            overlayReport: false,
        }
    },

    computed: {
        academicYear () {
            return this.academicTermId.toString().substring(0,4) + '-' + (parseInt(this.academicTermId.toString().substring(0,4)) + 1)
        },

    },

    methods: {
        ...mapMutations({
            setAcademicTermId: 'markSheet/setAcademicTermId',
            setFormClassId: 'markSheet/setFormClassId'
        }),

        ...mapActions({
            getMarkSheetData: 'markSheet/getMarkSheetData'
        }),

        async initialize () {
            this.loading = true;
            this.overlay = true;
            this.setAcademicTermId(this.academicTermId);
            this.setFormClassId(this.formClassId);

            try {
                const { data: { marks, subjects } } = await this.getMarkSheetData();
                this.mapHeaders(subjects);
                this.mapMarks(marks);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
            }
            this.overlay = false;
            this.loading = false;
        },

        mapHeaders (data) {
            this.headers.push({
                text: 'Student Name',
                value: 'name',
                width: '180',
                sortable: false
            })
            data.forEach(subject => {
                this.headers.push({
                    text: subject.abbr,
                    value: subject.id,
                    width: '100',
                    sortable: false
                });
            });
            console.log(this.headers)
        },

        mapMarks (data) {
            data.forEach(record => {
                let { term_marks } = record;
                let markSheetRecord = {};
                markSheetRecord.name =  record.last_name + ', ' + record.first_name;
                // markSheetRecord.marks = term_marks;
                term_marks.forEach(mark => {
                    markSheetRecord[mark.subject_id] = mark
                })
                this.markRecords.push(markSheetRecord)
            })
        },

        closeDialogMarkSheet () {
            console.log('close marksheet')
            this.$emit('close-marksheet');
        },

        courseMarkClass (item) {
            if(item.assigned && item.course_mark !== null){
                return 'mark-field__content mr-2'
            }
            return 'mark-field__content mr-2 amber lighten-1'
        },

         examMarkClass (item) {
            if(item.assigned && item.exam_mark !== null){
                return 'mark-field__content'
            }
            return 'mark-field__content amber lighten-1'
        },

        viewPDF () {
            this.dialogReport = true;
            this.overlayReport = true;
            this.src = process.env.VUE_APP_API_URI + "/api/mark-sheet/"+this.academicTermId+"/"+this.formClassId;
            console.log(this.src);
        },

        pdfLoaded () {
            this.overlayReport = false;
        },

        closeReport () {
            this.src = null;
            this.dialogReport = false;
        }
    }
}
</script>

<style scoped>

    .mark-field__content{
        border: 1px solid gainsboro;
        padding: 2px 2px;
        font-size: 14px;
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
   
</style>