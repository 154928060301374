<template>
    <v-card
        flat
    >
        <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search for teacher"
                single-line
                hide-details
                clearable
            ></v-text-field>
        </v-card-title>    
        <v-data-table
            class="mt-10"
            :headers="headers"
            :items="employees"
            :search="search"
            fixed-header
            height="25vh"
            :loading="loading"
        >
            <template v-slot:top>
                <employee-lessons></employee-lessons>
            </template>
            
            <template v-slot:[`item.actions`]="{ item }">
                <v-btn
                    text
                    small
                    outlined
                    color="primary"
                    class="mr-4"
                    @click="editItem(item)"
                >
                    <v-icon
                        small
                        class="mr-2"                                       
                    >
                        mdi-pencil
                    </v-icon>
                    Timetable
                </v-btn>                               
            </template>
        </v-data-table>              
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EmployeeLessons from './EmployeesEditTimetableLessons';
export default {
    components: {
        EmployeeLessons,
    },

    created () {
        this.initialize();
    },

    data: () => ({
         headers: [
            {text: 'ID', align: 'start', sortable: 'false', value: 'id', width: '50'},
            {text: 'Last Name', value: 'last_name', width: '100'},
            {text: 'First Name', value: 'first_name', width: '100'},
            {text: 'User Name', value: 'user_name', width: '100'},
            {text: '', value: 'actions', sortable: 'false', width: '100'}
        ], 
        editedIndex: -1,
        search: '',       
        dialogEdit: false,
        dialogDelete: false,
        editDialog: false,
        editedTeacher: {},
        deletedTeacher: {}, 
        selectFormClass: '',
        selectEditClass: '', 
        editLessonClass: '',
        editLessonSubject: {id: '', title: ''},
        loading: false,
    }),

    methods: {
        ...mapMutations({
            setDialog: 'timetable/setDialog',
            setEditedEmployee: 'timetable/setEditedEmployee',
            setFormClasses: 'timetable/setFormClasses',
            setSubjects: 'timetable/setSubjects',
        }),

        ...mapActions({
            getFormClasses: 'timetable/getFormClasses',
            getSubjects: 'timetable/getSubjects',
        }),

        async initialize () {
            this.loading = true;            
            const formClassesPromise = this.getFormClasses();
            const subjectsPromise = this.getSubjects();

            const [ dataFormClasses , dataSubjects ] =
            await Promise.all([formClassesPromise, subjectsPromise]);
            
            this.mapFormClasses(dataFormClasses);
            this.mapSubjects(dataSubjects);
        },

        mapFormClasses ({ data }) {
            let formClasses = [];
            data.forEach(record => {
                formClasses.push(record.id);
            });
            this.setFormClasses(formClasses);
        },

        mapSubjects ({ data }) {
            let subjects = [];
            data.forEach(record => {
                let subject = {
                    id: record.id,
                    title: record.title
                }
                subjects.push(subject);
            });
            this.setSubjects(subjects);
        },

        editItem (item) {            
            this.setEditedEmployee(item);
            this.setDialog(true);
        }
    },

    watch: {
        employees: {
            handler (val){
                //console.log('employees loaded..');
                if(val.length > 0) this.loading = false
            }
        }
    },

    computed: {
        ...mapGetters({
            employees: 'employees/getEmployees',
        })
    }
}
</script>