var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"90vw","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"height":"90vh"}},[_c('v-toolbar',{staticClass:"pr-3",attrs:{"color":"primary","dark":"","height":"50"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.academicYear)+" "+_vm._s(_vm.formClassId)+" Class Mark Sheet ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","color":"white","small":""},on:{"click":_vm.closeDialogMarkSheet}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-title',{staticClass:"mb-6"},[_c('v-spacer'),_c('v-text-field',{staticClass:"mr-4",attrs:{"append-icon":"mdi-magnify","label":"Search Student","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":_vm.viewPDF}},[_vm._v("View PDF")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.markRecords,"height":"55vh","fixed-header":"","loading":_vm.loading,"hide-default-header":"","search":_vm.search},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"180px"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Student Name")])]),_vm._l((headers),function(header,j){return [(header.value !== 'name')?_c('th',{key:j,staticStyle:{"width":"100px"}},[_c('div',[_vm._v(_vm._s(header.text))]),(header.value !== 'name')?_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"mr-2",staticStyle:{"width":"32px"}},[_vm._v("CW")]),_c('span',{staticStyle:{"width":"32px"}},[_vm._v("EW")])]):_vm._e()]):_vm._e()]})],2)])]}},{key:"item",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('tr',_vm._g(_vm._b({},'tr',attrs,false),on),[_c('td',[_vm._v(_vm._s(props.item.name))]),_vm._l((props.headers),function(header,i){return [(header.value !== 'name')?_c('td',{key:i},[_c('div',{staticClass:"d-flex"},[(props.item[header.value].assigned)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:_vm.courseMarkClass(props.item[header.value])},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item[header.value].course_mark)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item[header.value].teacher)+" ")])]):_c('span'),(props.item[header.value].assigned)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:_vm.examMarkClass(props.item[header.value])},'span',attrs,false),on),[_vm._v(" "+_vm._s(props.item[header.value].exam_mark)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item[header.value].teacher)+" ")])]):_c('span')],1)]):_vm._e()]})],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(props.item.name))])])]}}])}),_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay,"color":"white"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1),_c('v-card-actions')],1),_c('v-dialog',{attrs:{"max-width":"90vw","persistent":""},model:{value:(_vm.dialogReport),callback:function ($$v) {_vm.dialogReport=$$v},expression:"dialogReport"}},[_c('v-card',{staticStyle:{"position":"relative","overflow-y":"hidden"},attrs:{"height":"90vh"}},[_c('v-toolbar',{staticClass:"pr-3",attrs:{"color":"primary","dark":"","height":"50"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.academicYear)+" "+_vm._s(_vm.formClassId)+" Class Mark Sheet ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","color":"white","small":""},on:{"click":function($event){return _vm.closeReport()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('iframe',{ref:"pdf",staticClass:"report-viewer",staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.src},on:{"load":_vm.pdfLoaded}}),_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlayReport,"color":"white","opacity":"0.60"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }