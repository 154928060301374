import axios from 'axios'

export default {
    namespaced: true,
    state: {
        subjectSelected: null,
        studentSelected: null,
        subjectStudentsAssignment: null,
    },
    getters: {
        getSubjectSelected(state){
            return state.subjectSelected
        },
        getStudentSelected(state){
            return state.studentSelected
        },
        getSubjectStudentsAssignment (state) {
            return state.subjectStudentsAssignment
        },
    },
    mutations: {
        setSubjectSelected(state, value){
            state.subjectSelected = value
        },
        setStudentSelected(state, value){
            state.studentSelected = value
        },
        setSubjectStudentsAssignment (state, value) {
            state.subjectStudentsAssignment = value
        },
    },
    actions: {
        getCurrentStudents(){
            let url = '/api/students';
            return axios.get(url);
        },
        getStudentsAssigned({ getters }){
            let url = '/api/subject-students/'
            + getters.getSubjectSelected;
            //console.log(url);
            return axios.get(url);
        },
        deleteSubjectAssignment({ getters }){
            let url = '/api/delete-student-subject-assignment';
            return axios.post(url, getters.getStudentSelected);
        },
        postStudentAssignment({ getters }){
            let url = '/api/student-subject-assignment';
            console.log( getters.getStudentSelected )
            return axios.post(url, getters.getStudentSelected);
        },
        postSubjectStudentsAssignmentBatch ({ getters }) {
            const url = 'api/subject-students-batch';    
            console.log(getters.getSubjectStudentsAssignment)        
            return axios.post(url, getters.getSubjectStudentsAssignment);
        },

    }
}