<template>
    <v-btn        
        text
        v-on:click="logOut"
        color="primary"
      >
        <span class="mr-2">Logout</span>
        <v-icon>mdi-logout</v-icon>
      </v-btn>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            primaryColor: 'app/getPrimaryColor',
        })
        
    },
    methods: {
        ...mapActions({
            signOut: 'auth/signOut'
        }),

        ...mapMutations({
            setOverlay: 'app/setAppOverlay',
            setAuthenticated: 'auth/setAuthenticated',
            setStep: 'auth/setStep',
        }),

        async logOut () {
            this.setAuthenticated(false);            
            this.setOverlay(true);
            try {
                await this.signOut();
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }
            sessionStorage.clear();
            this.setOverlay(false);
            this.setStep(1);
            this.$router.replace('/');
        }        
    }
}
</script>