<template>
    <v-tab-item style="overflow-y:auto; height: 70vh">                                                
        <v-card
            width="400"
            flat
            class="mx-auto mt-3 px-4"
        >
            <v-alert
                text
                type="error"
                v-if="errorMessage"
            >
                {{ errorMessage }}
            </v-alert>

            <v-alert
                dense
                text
                type="success"
                v-if="successMessage"
            >
                {{ successMessage }}
            </v-alert>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="first_name"
                        v-model="record.first_name"
                        label="First Name"
                        hide-details="auto"
                        :rules="rules"
                        @blur="validate('first_name')"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.first_name.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.first_name.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.first_name.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="last_name"
                        v-model="record.last_name"
                        label="Last Name"
                        hide-details="auto"
                        :rules="rules"
                        @blur="validate('last_name')"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.last_name.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.last_name.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.last_name.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">                                            
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"                   
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="record.date_of_birth"
                            label="Date of Birth (Optional)"                                                    
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        >
                            <template v-slot:append>
                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="fields.date_of_birth.saveProgress"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="fields.date_of_birth.saveSuccess"
                                        small
                                        color="green"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="fields.date_of_birth.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>
                            </template>
                        </v-text-field>
                        </template>
                        <v-date-picker
                            ref="picker"
                            v-model="record.date_of_birth"                                                
                            min="1950-01-01"
                            @change="saveDate"                                                
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        ref="birth_certificate_pin"
                        v-model="record.birth_certificate_pin"
                        label="Birth Certificate Pin"
                        hide-details="auto"
                        @blur="validate('birth_certificate_pin')"
                        :rules="rules"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.birth_certificate_pin.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.birth_certificate_pin.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.birth_certificate_pin.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-select
                        ref="gender"
                        v-model="record.gender"
                        :items="gender"
                        label="Gender (Optional)"
                        hide-details
                        @change="validate('gender')"
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.gender.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.gender.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.gender.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-autocomplete
                        ref="form_class_id"
                        v-model="record.form_class_id"
                        :items="formClasses"
                        item-text="id"
                        item-value="id"
                        label="Form Class"
                        hide-details="auto"
                        @change="validate('form_class_id')"
                        :rules="rules"                                                                   
                    >
                        <template v-slot:append>
                            <v-fade-transition>
                                <v-progress-circular
                                    v-if="fields.form_class_id.saveProgress"
                                    size="16"
                                    width="3"
                                    color="info"
                                    indeterminate
                                ></v-progress-circular>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.form_class_id.saveSuccess"
                                    small
                                    color="green"                                        
                                >
                                    mdi-check-all
                                </v-icon>
                            </v-fade-transition>

                            <v-fade-transition>    
                                <v-icon
                                    v-if="fields.form_class_id.saveError"
                                    small
                                    color="red"                                        
                                >
                                    mdi-alert-circle
                                </v-icon>
                            </v-fade-transition>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </v-card>                                                
    </v-tab-item>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created: function () {
        this.record = { ...this.studentRecord };
        // console.log('created...')
        // console.log(`New student: ${this.newStudent}`)
        // console.log(this.record);
        // this.setFields();
    },
    
    props:{       
        
        formClasses: {
            type: Array,
            default: function () {
                return []
            }
        },

        newStudent: {
            type: Boolean,
            default: false,
        }
    }, 

    watch: {       

        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },        

        // studentRecord: {
        //     handler () {
        //         this.record = { ...this.studentRecord };
        //         // this.resetFields();
        //         console.log('student record changed...')
        //     }
        // }
    },

    data: () => ({
        gender: ['M', 'F'],       
        rules: [
            value => !!value || 'Required'
        ],
        menu: false,
        record: {
            student_id: null,
            first_name: null,
            last_name: null,
            other_name: null,
            date_of_birth: null,
            birth_certificate_pin: null,
            gender: null,
            form_class_id: null,
        },
        fields: {
            first_name: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            last_name: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            date_of_birth: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            birth_certificate_pin: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            gender: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },
            form_class_id: {
                saveProgress: false,
                saveError: false,
                saveSuccess: false,
            },            
        },
        errorMessage: null,
        successMessage: null,
        validateInline: true,
    }),

    computed: {
        ...mapGetters({
            studentRecord: 'students/getEditedStudent',
        })
    },

    methods: {
        ...mapActions({
            postStudent: 'students/postStudent',
            postClassRegistration: 'students/postClassRegistration',
        }),

        ...mapMutations({
            setEditedStudent: 'students/setEditedStudent',
            setClassRegistration: 'students/setClassRegistration',
        }),
        
        initialize () {
            this.record = { ...this.studentRecord };
            if(this.newStudent) this.validateInline = false;
        },

        saveDate(date){
            this.$refs.menu.save(date);
            this.validate('date_of_birth');
        },

        validate (model) {
            let formHasErrors = false;
            console.log(model);
            console.log(this.record);
            // if(!this.validateInline) return;

            this.setEditedStudent({
                birth_certificate_pin: this.record.birth_certificate_pin,
                date_of_birth: this.record.date_of_birth,
                first_name: this.record.first_name,
                form_class_id: this.record.form_class_id,
                gender: this.record.gender,
                other_name: this.record.other_name,
                id: this.record.student_id,                
                last_name: this.record.last_name,
            });

            this.setClassRegistration({
                id: this.record.student_id,
                form_class_id: this.record.form_class_id
            })
            
            Object.keys(this.record).forEach( f => {
                if(
                    (
                        f === 'birth_certificate_pin' ||
                        f === 'first_name' ||
                        f === 'last_name' ||
                        f === 'form_class_id'
                    ) &&
                    !this.record[f]
                ){
                    formHasErrors = true;
                    this.$refs[f].validate(true);
                    this.errorMessage = "Please fill out the missing fields.";
                }                
            })
            // console.log(`Form has errors: ${formHasErrors}`)
            if(formHasErrors) return false;
            // console.log(this.record);
            if(this.record.student_id){
                this.saveFeedback(model, "saveProgress");
                this.update(model);
            }
            else{
                return true;
            }
            
        },

        async update (model = null) {
            console.log("updating...")
            this.errorMessage = null;
            this.successMessage = null;            
            try {
                if(!this.record.student_id){
                    //new student
                    const { data: { student } } = await this.postStudent();
                    console.log(student);
                    this.record.student_id = student.id;
                    this.$emit('enable-tabs');
                    this.successMessage = "Student Successfully added."
                    // await this.postClassRegistration();
                }
                else if(model === 'form_class_id'){
                    const { data } = await this.postClassRegistration();
                    this.record.student_id = data.student_id;
                    this.setEditedStudent(data);
                    console.log(data);
                }
                else{
                    const { data: { Student } }  = await this.postStudent();
                    this.record.student_id = Student.id;
                    Student.student_id = Student.id;
                    console.log(Student);
                    this.setEditedStudent(Student);
                }
                
                this.saveFeedback(model, "saveSuccess");
                this.$emit('update-students', this.record.id);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.saveFeedback(model, "saveError");
                this.errorMessage = "An error has occured."
            }           
                     
        },
       

        saveFeedback (model, status) {
            // console.log(model);
            if(!model) return;

            let saveIcons = this.fields[model];
            Object.keys(saveIcons).forEach(key => {
                saveIcons[key] = false;
            });
            
            this.$nextTick(() => {
                saveIcons[status] = true;
            })
        },

        resetFields () {
            Object.keys(this.fields).forEach(key => {
                key.saveProgress = false,
                key.saveError = false,
                key.saveSuccess = false
            })
        }
    }
}
</script>