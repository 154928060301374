<template>
    <v-card
        outlined
        width="820"
        class="mx-auto pa-4"
    >
        <v-tabs 
            grow
            v-model="tab"
        >
            <v-tab
                v-for="item in tabOptions"
                :key="item"
            >
                {{ item }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item>
                <student-subject-single></student-subject-single>
            </v-tab-item>
            <v-tab-item>
                <StudentSubjectBatch
                    v-bind:subjects="subjects"
                    v-bind:teachers="employees"
                    v-bind:formClasses="formClasses"
                    v-bind:formLevels="formLevels"
                    v-bind:students="students"
                ></StudentSubjectBatch>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
    
</template>

<script>
import StudentSubjectSingle from "./StudentSubjectsSingle.vue";
import StudentSubjectBatch from "./StudentSubjectsBatch.vue";
import { mapActions } from 'vuex';
export default {
    components: {
        StudentSubjectSingle,
        StudentSubjectBatch,
    },

    created: function () {
        this.initialize();
    },

    data: () => ({
        tab: null,
        tabOptions: [
            'Subject Student Assignment',
            'Batch Assignment'
        ],           
        subjects: [],
        students: [],
        employees: [],
        formClasses: [],
        formLevels: [],
    }),

    methods: {
        ...mapActions({
            getStudents: 'subjectAssignments/getCurrentStudents',
            getFormClasses: 'timetable/getFormClasses',
            getSubjects: 'timetable/getSubjects',
            getEmployees: 'employees/getEmployees',
        }),

        async initialize(){
            try {
                const promiseGetStudents = this.getStudents();
                const promiseGetFormClasses = this.getFormClasses();
                const promiseGetSubjects = this.getSubjects();
                const promiseGetEmployees = this.getEmployees();

                const [
                    { data: dataStudents },
                    { data: dataFormClasses },
                    { data: dataSubjects },
                    { data: dataEmployees }
                ] = await Promise.all([
                    promiseGetStudents,
                    promiseGetFormClasses,
                    promiseGetSubjects,
                    promiseGetEmployees
                ])

                this.students = dataStudents;
                this.hasDuplicates(dataStudents)
                
                dataFormClasses.forEach(formClass => {
                    if(this.formLevels.indexOf(formClass.form_level) === -1){
                        this.formLevels.push(formClass.form_level)
                    }
                })
                this.formClasses = dataFormClasses
                this.subjects = dataSubjects;
               

                dataEmployees.forEach(employee => {
                    employee.name = employee.first_name[0] + '. ' + employee.last_name;
                })

                this.employees = dataEmployees;


            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error)
                this.loading = false;
            }
        }, 
        
        hasDuplicates (data) {
            let students = [];
            let duplicates = [];
            data.forEach(value => {
                
                if(students.indexOf(value.student_id) === -1){
                    students.push(value.student_id);
                }
                else duplicates.push(value.student_id)
            })

            console.log(duplicates)
        }
    }
}
</script>