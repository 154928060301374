<template>
    <layout>        

        <template v-slot:username>
            <v-form 
                class="pa-5"
                @submit.prevent
            >
                <v-text-field
                    label="Username"
                    placeholder="Enter username"
                    name="id"                                                
                    type="text"
                    color="primary"
                    v-model="formData.name"
                    clearable
                    autofocus
                    v-on:keyup="keyPress"
                    auto-complete="off"
                >
                    <v-icon
                        slot="prepend"
                        color="primary"
                    >
                        mdi-account
                    </v-icon>
                </v-text-field>
                <div class="text-right mt-4 mb-4">                                                
                    <v-btn 
                        color="primary"
                        @click="nextStep" 
                        dark
                        block
                    >NEXT</v-btn>
                </div>
            </v-form>
        </template>

        <template v-slot:password>
            <v-form 
                class="pa-5"
                @submit.prevent="submit"
            >
                <v-text-field
                    v-show="password"
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    :label="passwordLabel"
                    :placeholder="passwordPlaceholder"
                    :hint="passwordHint"
                    persistent-hint
                    @click:append="show = !show"                                                    
                    name="password"
                    v-model="formData.password"
                    clearable
                    autofocus
                    color="primary"
                >
                    <v-icon
                        slot="prepend"
                        color="primary"
                    >
                        mdi-key
                    </v-icon>
                </v-text-field>
            

                <div class="text-right mt-4  mb-4">
                    <v-btn 
                        color="primary"
                        @click="submit" 
                        dark
                        block
                     >
                        LOGIN
                     </v-btn>
                </div>
            </v-form>             
        </template>

    </layout>
</template>

<script>
import Layout from '../layouts/Login';
import { mapGetters, mapActions, mapMutations } from 'vuex';
export default {
    name: 'LoginTermReports',
    components: {
        Layout
    },
    data: () => ({
        formData: {
            name: '',
            password: '',            
        },
        show: false,        
        passwordPlaceholder: 'Enter password',
        passwordLabel: 'Password',
        passwordHint: '',
        password: true,
    }),

    computed: {
        ...mapGetters({            
            step: 'auth/getStep',
            authenticated: 'auth/getAuthenticated',
            resetPassword: 'auth/getResetPassword',
            employeeInitials: 'auth/getEmployeeInitials',
            employee: 'auth/setEmployeeSignedIn'                       
        }),        
    },

    methods: {
        ...mapActions({
            signIn: 'auth/signIn', 
            getUser: 'auth/user',
            getUserPermissionList: 'app/getUserPermissionList',
            setAuthenticated: 'auth/setAuthenticated',
            userPermissions: 'auth/userPermissions',
            serverError: 'auth/serverError',          
        }),

        ...mapMutations({
            loginError: 'auth/setLoginErrors', 
            setStep: 'auth/setStep',
            setOverlay: 'auth/setOverlay',
            setUserName: 'auth/setUserName',
            setResetPassword: 'auth/setResetPassword',
            setPermissions: 'auth/setPermissions',
            setEmployeeId: 'auth/setEmployeeId',
            setCredentials: 'auth/setCredentials',
            setAuthenticated: 'auth/setAuthenticated',
            setEmployeeSignedIn: 'auth/setEmployeeSignedIn',
            setEmployeeInitials: 'auth/setEmployeeInitials' ,
            setUser: 'auth/setUser',          
        }),

        async nextStep () {
            this.setOverlay(true);
            //console.log('next step');            
            this.setUserName(this.formData.name);
            try {
                const { data: {
                    employee: {
                        id, 
                        first_name, 
                        last_name, 
                    },
                    user: {
                        password_reset
                    },
                    permissions 
                    
                } } = await this.getUser();
                // const response = await this.getUser();
                // console.log(response);
                // console.log(permissions);
                const user = {
                    initials: first_name[0] + last_name[0],
                    first_name: first_name,
                    last_name: last_name,
                    employee_id: id,
                    permissions: permissions
                }
                this.setUser(JSON.stringify(user));
                sessionStorage.setItem("user", JSON.stringify(user));
                this.setOverlay(false);
                this.loginError('');
                if(password_reset == 1){
                    this.setResetPassword(true);
                }
                else{
                    this.setResetPassword(false);                    
                }
                this.setStep(2); 

            } catch (error) {
                this.setOverlay(false);
                //console.log(error);
                if(error.response) console.log(error.response);
                else console.log(error);
                if(error.response){
                    this.loginError(error.response.data.message);
                }
                else{
                    this.loginError(`An Error has occured ( ${error})`)
                }
                
            }
            
            
        },

        async submit () {            
            console.log('Authenticating...');
            this.setOverlay(true);
            this.setCredentials(this.formData);
            try {
                await this.signIn();
                console.log('Authenticated.')                
                this.loginError('');                
                if(this.resetPassword == 1){
                    this.setStep(3);                    
                }
                else{
                    this.setAuthenticated(true);
                    sessionStorage.setItem("authenticated", "true");  
                    this.$router.replace('/app');
                }                 
            } catch (error) {
                this.setOverlay(false);
                // console.log(error.response);
                this.serverError(error);
                
            }
            this.setOverlay(false);
        },
        
        keyPress (e) {
            if(e.keyCode === 13){
                this.nextStep();
            }
        },
        
    },
}
</script>